"use strict";
exports.__esModule = true;
exports.formatPhoneNumber = void 0;
exports.formatPhoneNumber = function (value) {
    if (!value) {
        return value;
    }
    // Filter only numbers from the input
    var cleaned = value.replace(/\D/g, '');
    // Check if the input is of correct (takes only 79999999999 numbers now)
    var match = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
        // Remove the matched extension code
        // Change this to format for any country code.
        var intlCode = match[1] ? '+7 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('');
    }
    return '';
};
