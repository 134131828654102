import { createSelector } from '@reduxjs/toolkit';

import fp from 'lodash/fp';

import { IDTORFInfoClientLineFullResponse } from 'dto/rf-info';

import { name } from './index';

export const selectState = fp.path(name);

const selectData = createSelector(selectState, fp.path('data'));
const selectDataFull = createSelector(selectState, fp.path('dataFull'));
const selectFilter = createSelector(selectState, fp.path('filter'));
const selectCurrentId = createSelector(selectState, fp.path('currentId'));

export default {
  selectData,
  selectDataWithFieldList: createSelector(
    selectData,
    (state) => (fieldList: string[]) =>
      fp.map((clientLine: { [key: string]: string }) => fp.paths(fieldList, clientLine), state)
  ),
  selectCurrentClientLine: createSelector(
    selectDataFull,
    selectCurrentId,
    (data: IDTORFInfoClientLineFullResponse, id: number) => {
      console.log(`client-line/selectors/selectCurrentClientLine: data = `, data);
      console.log(`client-line/selectors/selectCurrentClientLine: id = `, id);
      const result = fp.pathOr({}, id, data);
      console.log(`client-line/selectors/selectCurrentClientLine: result = `, result);
      return result;
    }
  ),
  selectRate: createSelector(selectState, fp.path('rate')),
  selectPageCount: createSelector(selectState, fp.path('pageCount')),
  selectFilter,
  selectFilterByField: createSelector(
    selectFilter,
    (state) => (field: string) => fp.path(field, state)
  ),
  selectIsLoading: createSelector(selectState, fp.path('isLoading')),
  selectErrorListByField: createSelector(selectState, (state) => (field: string) => {
    return fp.pathOr([], ['errorList', field], state);
  }),
};
