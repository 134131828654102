import React from 'react';

import { selectors } from 'modules/accounts/store';
import { store } from 'root-store';
import AccountsFilter from '../AccountsFilter';
import ExtendedStatement from '../ExtendedStatementPage';
import styles from './assets/styles/index.module.css';
import { Condition, MessageList } from '@rfb/ui-kit';

const AccountsMain = () => {
  const state = store.getState();
  const accountId = selectors.selectCurrentId(state);
  const errorList = selectors.selectErrorListByField(state)('rfInfo');

  const isError = errorList.length > 0;
  return (
    <div className={styles.main}>
      <AccountsFilter />
      <Condition value={!isError}>
        <ExtendedStatement id={accountId} />
      </Condition>
      <Condition value={isError}>
        <MessageList type="error" messages={errorList} />
      </Condition>
    </div>
  );
};

export default AccountsMain;
