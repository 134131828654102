import { Logger } from '@rfb/common';
import React, { Component, ErrorInfo, ReactNode } from 'react';
import AppError from './AppError';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  errorMessage: string;
  stack: string;
}

export type TLogMessage = {
  messageText: string;
  stackTrace: string;
};

function parseLogMessage(logMessage: string): TLogMessage {
  console.log(`parseLogMessage BEGIN: logMessage = ${JSON.stringify(logMessage)}`);
  const parts = logMessage.split('\n');
  const messageText = parts[0].trim();
  const stackTrace = parts.slice(1).join('\n').trim();
  const result: TLogMessage = {
    messageText,
    stackTrace,
  };
  console.log(`parseLogMessage END: result = ${JSON.stringify(result)}`);
  return result;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorMessage: '',
    stack: '',
  };

  public static getDerivedStateFromError(error: Error): State {
    console.log(`getDerivedStateFromError BEGIN`);
    const result = {
      hasError: true,
      errorMessage: error.message,
      stack: error.stack ?? '',
    };
    // const logMessage: TLogMessage = error.stack ? parseLogMessage(error.stack) : null;
    console.log(`getDerivedStateFromError: error = ${JSON.stringify(error)}`);
    console.log(`getDerivedStateFromError: error.message = ${JSON.stringify(error.message)}`);
    console.log(`getDerivedStateFromError: error.stack = ${JSON.stringify(error.stack)}`);

    // const logMessage: TLogMessage = parseLogMessage(error.message);
    const logMessage: TLogMessage | null = error.stack ? parseLogMessage(error.stack) : null;
    console.log(`getDerivedStateFromError: logMessage = ${JSON.stringify(logMessage)}`);
    Logger.getInstance().error(`App: ${logMessage ? JSON.stringify(logMessage) : error.message}`);

    console.log(`getDerivedStateFromError END: result = ${JSON.stringify(result)}`);
    return result;
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(`componentDidCatch BEGIN`);
    console.error('Uncaught error:', error, errorInfo);

    const strStack = error.stack;
    console.log(`componentDidCatch: strStack = ${strStack}`);
    console.log(`componentDidCatch END`);
  }

  public render() {
    if (this.state.hasError) {
      // return <AppError message={this.state.errorMessage} />;
      return <AppError />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
