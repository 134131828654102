import { put, select, takeEvery } from 'redux-saga/effects';

import fp from 'lodash/fp';

import { convertPathUsingParams, Directions, getTransformedAmount } from '@rfb/common';

import new_api from 'utils/new-api';

import notification from 'utils/notification';
import storageDB from 'utils/storage-db';

import { convertApiErrorCodesToMessages } from 'helpers/app';
import { download } from 'helpers/file';

import apiEndpoints from 'configs/api/endpoints';
import notificationTranslationConfig from 'configs/notification/translations';
import routingConfig from 'configs/routing';

import { IDTORFInfoHeaders, IDTORFInfoTranchesResponse } from 'dto/rf-info';

import { selectors as clientLineSelectors } from 'modules/client-line/store';
import { actions, selectors } from './index';
import trancheSelectors from './selectors';
import { ITrancheState } from './slice';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { ClientLineTypes } from 'modules/client-line/configs/type';
import { TrancheStatus } from '../configs/status';

function isRusfinance(type: string) {
  return type === ClientLineTypes.CC || type === ClientLineTypes.DC;
}

function* getData(action: TODO_ANY) {
  console.log(`//sagas/getData BEGIN: action.payload = `, action.payload);
  const filter: ITrancheState['filter'] & { clientLineId?: string } = action.payload;
  const numberLineParam =
    action.payload.multiBrand && isRusfinance(filter.type)
      ? ''
      : `number_line=${filter.clientLineId}`;
  console.log(`//sagas/getData: filter.type = `, filter.type);
  console.log(`//sagas/getData: isRusfinance(filter.type) = `, isRusfinance(filter.type));
  console.log(`//sagas/getData: numberLineParam = `, numberLineParam);
  try {
    yield put(actions.set({ data: [], pageCount: 0, isLoading: true }));
    yield put(actions.setError({ rfInfo: [] }));
    let url: string =
      apiEndpoints.rfInfo.tranches +
      `?type_line=${filter.type}&${numberLineParam}&status_tranche=${
        filter.status || TrancheStatus.ALL
      }` +
      `&start_date=${filter.dateStart}&end_date=${filter.dateEnd}` +
      `&finish_date_from=${filter.dateFinishStart}&finish_date_to=${filter.dateFinishEnd}` +
      `&page=${filter.page}` +
      `&sort=${fp.isEqual(Directions.DESC, filter.sorting?.direction) ? '-' : ''}${
        filter.sorting?.value
      }` +
      `&vin=${filter.vin}&name_brand=${filter.brand}&model=${filter.model}&number=${filter.number}` +
      `&per-page=${filter.perPage}`;
    url +=
      action.payload.multiBrand === undefined
        ? ''
        : `&multi_brand=${action.payload.multiBrand ? 'true' : 'false'}`;
    const result: { data: IDTORFInfoTranchesResponse; headers: IDTORFInfoHeaders } =
      yield new_api.get(url);
    yield put(actions.getDataSuccessful({ data: result.data, headers: result.headers }));
  } catch (error) {
    yield put(actions.getDataFailure(error));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* getDataSuccessful(action: TODO_ANY) {
  yield put(
    actions.set({
      data: action.payload.data.tranches,
      dataFilter: action.payload.data.filters,
      pageCount: fp.toNumber(action.payload.headers['x-pagination-page-count']),
    })
  );
  if (action.payload.data.exception) {
    yield put(
      actions.setError({ rfInfo: convertApiErrorCodesToMessages(action.payload.data.exception) })
    );
  }
}

function* getDataFailure(action: TODO_ANY) {
  yield put(actions.setError({ rfInfo: convertApiErrorCodesToMessages(action.payload) }));
}

function* trancheRepaymentDocRequestConfirm(action: TODO_ANY) {
  try {
    yield put(actions.set({ isSending: true }));
    yield put(actions.set({ repaymentData: action.payload.data }));

    const state = yield select();
    action.payload.history.push(routingConfig.trancheRepaymentConfirmation.path, {
      repaymentData: trancheSelectors.selectRepaymentData(state),
    });
  } catch (error) {
    yield put(actions.setError({ api: action.payload }));
  } finally {
    yield put(actions.set({ isSending: false }));
  }
}

function parseMoney(money: TODO_ANY) {
  const money1 = money.replace('?', '').replace(/\s/g, '').replace(',', '.');
  const result = parseFloat(money1);
  return result;
}

function* trancheRepayment(action: TODO_ANY) {
  try {
    yield put(actions.set({ isSending: true }));
    const response = yield new_api.post(
      apiEndpoints.rfInfo.tranchesPaymentDocRequest,
      action.payload.repaymentData
    );
    yield put(actions.set({ repaymentFileName: response.data.pdf_file_name }));

    const state = yield select();
    const amount: TODO_ANY = fp.pipe(
      selectors.selectRepaymentListAmount,
      getTransformedAmount
    )(state);
    const sendPaymentListRequest = {
      pdf_file_name: response.data.pdf_file_name,
      payments: action.payload.repaymentData.payments,
      doc_timestamp: response.data.doc_timestamp,
      total_repayment_amount: parseMoney(amount),
    };
    yield put(
      actions.sendPaymentList({ data: sendPaymentListRequest, history: action.payload.history })
    );
  } catch (error) {
    yield put(actions.setError({ api: action.payload }));
  } finally {
    yield put(actions.set({ isSending: false }));
  }
}

function* sendPaymentList(action: TODO_ANY) {
  try {
    yield put(actions.set({ isSending: true }));
    yield new_api.post(apiEndpoints.rfInfo.tranchesPayment, action.payload.data);
    yield put(actions.set({ isTrancheRepaymentCompleted: true }));
    action.payload.history.push(routingConfig.repayment.path);
    notification.info(notificationTranslationConfig.sendPaymentSuccessful);
  } catch (error) {
    if (error.response.status === 500) {
      // Главным образом на случай отказа Documentum
      notification.info('Сервер недоступен. Повторите попытку позднее.');
      action.payload.history.push(routingConfig.repayment.path);
      //      yield put(actions.sendPaymentListFailure(error.response?.data)); По идее нужен такой вывод, но бизнес попросил пока плашку - по аналогии с блоком финансирования
    } else {
      yield put(actions.sendPaymentListFailure(error.response?.data));
    }
  } finally {
    yield put(actions.set({ isSending: false }));
  }
}

function* trancheRepaymentReset(action: TODO_ANY) {
  storageDB.set('tranche-payment-list', []);
  yield put(actions.set({ paymentList: [], orderList: [] }));
  yield put(actions.set({ repaymentData: { payments: [] } }));
  yield put(actions.set({ isTrancheRepaymentCompleted: false }));

  const state = yield select();
  const currentClientLine = clientLineSelectors.selectCurrentClientLine(state);

  action.payload.history.push(
    currentClientLine.number
      ? convertPathUsingParams(routingConfig.tranchesListByClientLineIdAndType.path, {
          clientLineId: currentClientLine.number,
          clientLineType: currentClientLine.type,
        })
      : routingConfig.tranches.path
  );
}

function* sendPaymentListFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: action.payload }));
}

function* trancheRepaymentDocDownload(action: TODO_ANY) {
  try {
    const url: string =
      apiEndpoints.rfInfo.tranchesPaymentDocDownload +
      `?filename=${action.payload.repaymentFileName}`;
    const config = {
      responseType: 'blob',
    };
    const response = yield new_api.get(url, config);
    const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', action.payload.repaymentFileName); //or any other extension
    document.body.appendChild(link);
    link.click();
    yield put(actions.trancheRepaymentReset({ history: action.payload.history }));
  } catch (error) {
    yield put(actions.setError({ api: action.payload }));
  }
}

function* exportData(action: TODO_ANY) {
  console.log(`//sagas/exportData BEGIN: action.payload = `, action.payload);
  const filter: ITrancheState['filter'] & { clientLineId?: string } = action.payload;
  const multiBrand = action.payload.multiBrand ? 'true' : 'false';
  const numberLineParam =
    action.payload.multiBrand && isRusfinance(filter.type)
      ? ''
      : `number_line=${filter.clientLineId}`;
  console.log(`//sagas/exportData: multiBrand = `, multiBrand);
  let url: string =
    apiEndpoints.rfInfo.tranchesExport +
    `?type_line=${filter.type}&${numberLineParam}&status_tranche=${
      filter.status || TrancheStatus.ALL
    }` +
    `&start_date=${filter.dateStart}&end_date=${filter.dateEnd}` +
    `&finish_date_from=${filter.dateFinishStart}&finish_date_to=${filter.dateFinishEnd}` +
    `&vin=${filter.vin}&name_brand=${filter.brand}&model=${filter.model}&number=${filter.number}`;
  url +=
    action.payload.multiBrand === undefined
      ? ''
      : `&multi_brand=${action.payload.multiBrand ? 'true' : 'false'}`;
  console.log(`//sagas/exportData: url = `, url);

  const config = {
    headers: { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
    responseType: 'arraybuffer',
  };
  try {
    const result: { data: IDTORFInfoTranchesResponse; headers: { 'content-type': string } } =
      yield new_api.get(url, config);
    yield put(actions.exportDataSuccessful(result));
  } catch (error) {
    yield put(actions.exportDataFailure(error.response?.data));
  }
}

function* exportDataSuccessful(action: TODO_ANY) {
  download(action.payload, `tranches-${Date.now()}`);
  yield;
}

function* exportDataFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: action.payload }));
}

export default function* () {
  yield takeEvery(actions.getData, getData);
  yield takeEvery(actions.getDataSuccessful, getDataSuccessful);
  yield takeEvery(actions.getDataFailure, getDataFailure);

  yield takeEvery(actions.trancheRepaymentDocRequestConfirm, trancheRepaymentDocRequestConfirm);

  yield takeEvery(actions.trancheRepaymentDocDownload, trancheRepaymentDocDownload);

  yield takeEvery(actions.trancheRepaymentDocApprove, trancheRepayment);

  yield takeEvery(actions.sendPaymentList, sendPaymentList);
  yield takeEvery(actions.trancheRepaymentReset, trancheRepaymentReset);
  yield takeEvery(actions.sendPaymentListFailure, sendPaymentListFailure);

  yield takeEvery(actions.exportData, exportData);
  yield takeEvery(actions.exportDataSuccessful, exportDataSuccessful);
  yield takeEvery(actions.exportDataFailure, exportDataFailure);
}
