import { ClientLineTypes } from '../../../client-line/configs/type';
import {
  trancheStatus,
  TrancheStatus,
  TrancheStatusValue,
  trancheStatusValue,
} from '../../configs/status';
import { trancheTypeTranslations } from '../../configs/type';

// TODO Устранить дублирование с client-line
export const getTransformedNumberForView = (number: string, type: string): string =>
  `${ClientLineTypes.CC !== type ? number + '-' : ''}${trancheTypeTranslations[type]}`;

export const getTransformedNumberForViewDC = (agreement_number: string): string =>
  `${agreement_number ? agreement_number : 'Б/H'}`;

export const getTransformedStatus = (status: TrancheStatus): string => trancheStatusValue[status];

export const getStatusId = (statusValue: TrancheStatusValue): TrancheStatus => {
  return trancheStatus[statusValue];
};
