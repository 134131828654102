import { put, takeLatest } from 'redux-saga/effects';

import fp from 'lodash/fp';

import { convertApiErrorCodesToMessages } from 'helpers/app';

import new_api from 'utils/new-api';

import { download } from 'helpers/file';

import apiEndpoints from 'configs/api/endpoints';

import { actions, IAccountsState } from './index';

import {
  IDTORFInfoAccountArrestsResponse,
  IDTORFInfoAccountExtendedStatementResponse,
  IDTORFInfoAccountsResponse,
  IDTORFInfoAccountTransactionResponse,
  IDTORFInfoHeaders,
} from 'dto/rf-info';

import { convertPathUsingParams } from '@rfb/common';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

function* getAccounts() {
  yield put(actions.set({ isLoading: true }));
  yield put(actions.setError({ rfInfo: [] }));
  try {
    // Все расчётные счета
    const url: string = apiEndpoints.rfInfo.accounts;
    const result: {
      data: IDTORFInfoAccountsResponse[];
      headers: IDTORFInfoHeaders;
    } = yield new_api.get(url);
    yield put(actions.getAccountsSuccessful(result.data));
  } catch (error) {
    yield put(actions.getAccountsFailure(error.response?.data));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* getAccountsSuccessful(action: TODO_ANY) {
  yield put(actions.setAccounts(action.payload));
}

function* getAccountsFailure(action: TODO_ANY) {
  yield put(actions.setError({ rfInfo: convertApiErrorCodesToMessages(action.payload) }));
}

function* getAccount(action: TODO_ANY) {
  yield put(actions.set({ isLoading: true }));
  yield put(actions.setError({ rfInfo: [] }));

  try {
    const startDate = action.payload.start;
    const endDate = action.payload.end;
    const page = action.payload.pageTransactions;

    const params = `?page=${page}&start_date=${startDate}&end_date=${endDate}`;
    const url =
      convertPathUsingParams(apiEndpoints.rfInfo.transactions, {
        accountId: action.payload.account,
      }) + `${params}`;

    const resultAccount: { data: IDTORFInfoAccountTransactionResponse } = yield new_api.get(url);

    yield put(actions.getAccountTransactionsSuccessful(resultAccount));
  } catch (error) {
    yield put(actions.getAccountTransactionsFailure(error.response?.data));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* getAccountSuccessful(action: TODO_ANY) {
  yield put(actions.setCurrentAccount(action.payload));
}

function* getAccountFailure(action: TODO_ANY) {
  yield put(actions.resetAccountData());
  yield put(actions.resetCurrentAccount());
  yield put(actions.setError({ rfInfo: convertApiErrorCodesToMessages(action.payload) }));
}

function* getAccountTransactions(action: TODO_ANY) {
  yield put(actions.set({ isLoading: true, pageTransactionsCount: 0 }));
  yield put(actions.setError({ rfInfo: [] }));

  try {
    yield put(actions.resetAccountData());
    const url = convertPathUsingParams(apiEndpoints.rfInfo.transactions, {
      accountId: action.payload.account,
    });

    const result: { data: IDTORFInfoAccountTransactionResponse } = yield new_api.get(url);
    yield put(actions.getAccountTransactionsSuccessful(result));
  } catch (error) {
    yield put(actions.getAccountTransactionsFailure(error.response?.data));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* getAccountTransactionsSuccessful(action: TODO_ANY) {
  yield put(actions.setAccountData(action.payload.data));
  yield put(
    actions.set({
      pageTransactionsCount: fp.toNumber(action.payload.headers['x-pagination-page-count']),
    })
  );
}

function* getAccountTransactionsFailure(action: TODO_ANY) {
  yield put(actions.set({ pageTransactionsCount: 0 }));
  yield put(actions.setError({ rfInfo: convertApiErrorCodesToMessages(action.payload) }));
}

function* getAccountArrests(action: TODO_ANY) {
  yield put(actions.setError({ rfInfo: [] }));

  try {
    yield put(actions.resetCurrentArrestsData());
    const page = action.payload.page;
    const url: string =
      convertPathUsingParams(apiEndpoints.rfInfo.arrests, {
        accountId: action.payload.account,
      }) + `?page=${page ?? 1}`;

    const result: { data: IDTORFInfoAccountArrestsResponse } = yield new_api.get(url);

    yield put(actions.getAccountArrestsSuccessful(result));
  } catch (error) {
    yield put(actions.getAccountArrestsFailure(error.response?.data));
  }
}

function* getAccountArrestsSuccessful(action: TODO_ANY) {
  yield put(actions.setCurrentArrests(action.payload.data));
  yield put(
    actions.set({
      pageArrestsCount: fp.toNumber(action.payload.headers['x-pagination-page-count']),
    })
  );
}

function* getAccountArrestsFailure(action: TODO_ANY) {
  yield put(actions.setError({ rfInfo: convertApiErrorCodesToMessages(action.payload) }));
}

function* getAccountExtendedStatement(action: TODO_ANY) {
  yield put(actions.set({ isLoading: true }));
  yield put(actions.setError({ rfInfo: [] }));

  try {
    const dateStart = action.payload.dateStart ? `&start_date=${action.payload.dateStart}` : '';
    const dateEnd = action.payload.dateEnd ? `&end_date=${action.payload.dateEnd}` : '';
    const params = `?page=${action.payload.page}${dateStart}${dateEnd}`;
    const url: string =
      convertPathUsingParams(apiEndpoints.rfInfo.statements, {
        accountId: action.payload.account,
      }) + `${params}`;
    const result: { data: IDTORFInfoAccountExtendedStatementResponse } = yield new_api.get(url);
    yield put(actions.set({ isLoading: false }));
    yield put(actions.getAccountExtendedStatementSuccessful(result));
  } catch (error) {
    yield put(actions.set({ isLoading: false }));
    yield put(actions.getAccountExtendedStatementFailure(error.response?.data));
  }
}

function* getAccountExtendedStatementSuccessful(action: TODO_ANY) {
  yield put(actions.setExtendedStatementsData(action.payload.data));
  yield put(
    actions.set({
      pageStatementsCount: fp.toNumber(action.payload.headers['x-pagination-page-count']),
    })
  );
}

function* getAccountExtendedStatementFailure(action: TODO_ANY) {
  yield put(actions.setError({ rfInfo: ['Ошибка при получения выписки'] }));
}

function* exportAccountExtendedStatement(action: TODO_ANY) {
  yield put(actions.set({ isLoading: true }));
  yield put(actions.setError({ rfInfo: [] }));

  try {
    const dateStart = action.payload.dateStart ? `start_date=${action.payload.dateStart}` : '';
    const dateEnd = action.payload.dateEnd ? `end_date=${action.payload.dateEnd}` : '';
    const params = `${dateStart}&${dateEnd}`;
    const url =
      convertPathUsingParams(apiEndpoints.rfInfo.statementsExport, {
        accountId: action.payload.account,
      }) + `?${params}`;

    const config = {
      headers: { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
      responseType: 'arraybuffer',
    };

    const response = yield new_api.get(url, config);

    yield put(actions.exportAccountExtendedStatementSuccessful(response));
  } catch (error) {
    yield put(actions.exportAccountExtendedStatementFailure(error.response?.data));
  }
}

function* exportAccountExtendedStatementSuccessful(action: TODO_ANY) {
  yield put(actions.set({ isLoading: false }));
  download(action.payload, `report-${Date.now()}`);
}

function* exportAccountExtendedStatementFailure(action: TODO_ANY) {
  yield put(actions.set({ isLoading: false }));
  yield put(actions.setError({ rfInfo: convertApiErrorCodesToMessages(action.payload) }));
}

function* exportTransactionsData(action: TODO_ANY) {
  try {
    const filter: IAccountsState['filter'] = action.payload;
    const params = `start_date=${filter.start}&end_date=${filter.end}`;
    const url =
      convertPathUsingParams(apiEndpoints.rfInfo.transactionsExport, {
        accountId: filter.account ?? '',
      }) + `?${params}`;

    const config = {
      headers: { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
      responseType: 'arraybuffer',
    };
    const result = yield new_api.get(url, config);
    yield put(actions.exportTransactionsDataSuccessful(result));
  } catch (error) {
    yield put(actions.exportTransactionsDataFailure(error.response?.data));
  }
}

function* exportTransactionsDataSuccessful(action: TODO_ANY) {
  download(action.payload, `transactions-${Date.now()}`);
  yield;
}

function* exportTransactionsDataFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: action.payload }));
}

function* exportAccountStatement1СFormat(action: TODO_ANY) {
  console.log(`//sagas/exportAccountStatement1СFormat BEGIN: action.payload = `, action.payload);
  try {
    const filter: IAccountsState['filter'] = action.payload;
    console.log(`//sagas/exportAccountStatement1СForma: filter = `, filter);
    const params = `start_date=${filter.start}&end_date=${filter.end}`;
    console.log(`//sagas/exportAccountStatement1СForma: params = `, params);
    const url =
      convertPathUsingParams(apiEndpoints.rfInfo.statementsExport, {
        accountId: filter.account ?? '',
      }) + `?${params}&output_type=txt-1c`;
    console.log(`//sagas/exportAccountStatement1СForma: url = `, url);
    const config = {
      headers: {
        Accept: 'text/plain',
      },
      responseType: 'blob',
    };
    const result = yield new_api.get(url, config);
    console.log(`//sagas/exportAccountStatement1СFormat: result = `, result);
    yield put(actions.exportAccountStatement1СFormatSuccessful(result));
  } catch (error) {
    console.log(`//sagas/exportAccountStatement1СFormat ERROR: error = `, error);
    yield put(actions.setError({ api: action.payload }));
  }
  console.log(`//sagas/exportAccountStatement1СFormat END`);
}

// TODO Устранить дублирование(много мест)
const extractFilenameFromContentDisposition = (disposition: string) => {
  let filename = '';
  if (disposition && disposition.startsWith('attachment')) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
};

function* exportAccountStatement1СFormatSuccessful(action: TODO_ANY) {
  const response = action.payload;
  console.log(`//sagas/exportAccountStatement1СFormatSuccessful: response = `, response);
  const contentDisposition = response.headers['Content-Disposition'];
  console.log(
    `//sagas/exportAccountStatement1СFormatSuccessful: contentDisposition = `,
    contentDisposition
  );
  const filename = contentDisposition
    ? extractFilenameFromContentDisposition(contentDisposition)
    : 'account_statements_1c.txt'; // Костыль - только для output_type=txt-1c (а по-хорошему нужно доразобраться с настройками CorsFilter в Tomcat)
  const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = action.payload.filename ?? filename;
  document.body.appendChild(link);
  link.click();
}

export default function* () {
  yield takeLatest(actions.getAccountArrests, getAccountArrests);
  yield takeLatest(actions.getAccountArrestsSuccessful, getAccountArrestsSuccessful);
  yield takeLatest(actions.getAccountArrestsFailure, getAccountArrestsFailure);

  yield takeLatest(actions.getAccountTransactions, getAccountTransactions);
  yield takeLatest(actions.getAccountTransactionsSuccessful, getAccountTransactionsSuccessful);
  yield takeLatest(actions.getAccountTransactionsFailure, getAccountTransactionsFailure);

  yield takeLatest(actions.getAccount, getAccount);
  yield takeLatest(actions.getAccountSuccessful, getAccountSuccessful);
  yield takeLatest(actions.getAccountFailure, getAccountFailure);

  yield takeLatest(actions.getAccounts, getAccounts);
  yield takeLatest(actions.getAccountsSuccessful, getAccountsSuccessful);
  yield takeLatest(actions.getAccountsFailure, getAccountsFailure);

  yield takeLatest(actions.getAccountExtendedStatement, getAccountExtendedStatement);
  yield takeLatest(
    actions.getAccountExtendedStatementSuccessful,
    getAccountExtendedStatementSuccessful
  );
  yield takeLatest(actions.getAccountExtendedStatementFailure, getAccountExtendedStatementFailure);

  yield takeLatest(actions.exportAccountExtendedStatement, exportAccountExtendedStatement);
  yield takeLatest(
    actions.exportAccountExtendedStatementSuccessful,
    exportAccountExtendedStatementSuccessful
  );
  yield takeLatest(
    actions.exportAccountExtendedStatementFailure,
    exportAccountExtendedStatementFailure
  );

  yield takeLatest(actions.exportTransactionsData, exportTransactionsData);
  yield takeLatest(actions.exportTransactionsDataSuccessful, exportTransactionsDataSuccessful);
  yield takeLatest(actions.exportTransactionsDataFailure, exportTransactionsDataFailure);

  yield takeLatest(actions.exportAccountStatement1СFormat, exportAccountStatement1СFormat);
  yield takeLatest(
    actions.exportAccountStatement1СFormatSuccessful,
    exportAccountStatement1СFormatSuccessful
  );
}
