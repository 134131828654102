export default {
  system: {
    status: '/system/availability',
  },
  auth: {
    tokenCreate: '/authentication/create-token',
    tokenRefresh: '/authentication/refresh-token',
    tokenDestroy: '/authentication/destroy-token',
  },

  confirmation: {
    sendSms: '/security/send-confirm-sms',
    checkCode: '/security/check-confirm-code',
  },

  feedback: {
    create: '/feedback/create',
  },

  profile: {
    agreement: '/profile/agreement',
    view: '/profile',
    validate: '/profile/validate',
    phoneNumber: '/profile/phone-validate',
    chooseDealer: '/profile/choose-dealer',
    dealerSystem: '/profile/dealer-system',
    phoneNumberChange: '/profile/phone-number-change',
    codeWordChange: '/profile/key-word-change',
  },

  operationData: {
    oldGet: '/operation-data/index',
    get: '/operation-data',
    getById: '/operation-data/:id',
    documentumFileDownload: '/documentum/:id',
    errorDownload: '/operation-data/:id/upload-protocol',
    revokeRequest: '/operation-data/:id/cancel',
    refreshStatus: '/operation-data/request/used/refresh-status',
    postSignAndSave: '/operation-data/repo-request/:id/sign',
    getCountAgreeRequest: '/operation-data/repo-request/count-agree-request',
    getOperationDataRepoRequestDataById: '/request-data/:requestDataId',
  },

  financialBlock: {
    get: '/request-data/new/index',
    getUsed: '/request-data/used',
    post: '/request-data/new',
    postUsed: '/request-data/used',
    put: '/request-data/new',
    delete: '/request-data',
    deleteUsed: '/request-data/used/:id',
    putUsed: '/request-data/used/:id',
    docRequest: '/tranches-request/doc-request',
    docRequestUsed: '/tranches-doc/request-used',
    // docRequestUsed: '/tranches-request/used/doc-request',
    docDownload: '/tranches-request/doc-download',
    docDownloadUsed: '/tranches-doc/download',
    // docDownloadUsed: '//tranches-request/used/doc-download',
    fileDownload: '/request-data/:id/invoice-file-body',
    putFileUpload: '/request-data/used/:id/attachment/:attachmentId',
    attachmentDownload: '/request-data/used/:id/attachment/:attachmentId',
    operationDataRequestUsed: '/operation-data/request/used',
    // РЕПО
    getRepoRequestData: 'repo-request-data',
    getRepoRequestDataById: 'repo-request-data/:id',
    postRepoRequestData: 'repo-request-data',
    deleteRepoRequestDataById: 'repo-request-data/:id',
    putRepoRequestDataById: 'repo-request-data/:id',
    getRepoRequestTypes: 'repo-request-data/repo-request-types',
    getRepoRequestDataBrands: 'repo-request-data/brands',
    getRepoRequestDataFinancingInfo: 'repo-request-data/financing-info',
    postRepoUpload: '/operation-data/repo-request/:operationDataId/attachments/upload',
    deleteRepoAttachment:
      '/operation-data/repo-request/:operationDataId/attachments/:operationAttachmentId',
    getRepoAttachments: '/operation-data/repo-request/:operationDataId/attachments',
    postGenerateFileForDownload:
      '/operation-data/repo-request/:operationDataId/attachments/generate',
    getGeneratedFileDownload:
      '/operation-data/repo-request/:operationDataId/attachments/:operationAttachmentId/download',
    checkLimitRest: '/operation-data/repo-request/:operationDataId/check-limit-rest',
    repoRequestApply: '/operation-data/repo-request/:operationDataId/apply',
    getCheckSupplierDocDate: '/repo-request-data/check-supplier-doc-date',
  },

  rfInfo: {
    accounts: '/rf-info/accounts',
    arrests: '/rf-info/accounts/:accountId/arrests',
    clientLine: '/rf-info/client-line',
    clientLines: '/rf-info/client-lines',
    info: '/rf-info/info',
    rate: '/rf-info/rates',
    transactions: '/rf-info/accounts/:accountId/transactions',
    transactionsExport: '/rf-info/accounts/:accountId/transactions/export',
    statements: '/rf-info/accounts/:accountId/statements',
    statementsExport: '/rf-info/accounts/:accountId/statements/export',
    // statementsExportTo1C: '/rf-info/accounts/:accountId/statements/export-to-1c',
    tranches: '/rf-info/tranches',
    tranchesPayment: '/rf-info/tranches/payment',
    tranchesExport: '/rf-info/tranches/export',
    tranchesRequest: '/rf-info/tranches/request',
    tranchesPaymentDocRequest: '/tranches-doc/payment',
    //tranchesPaymentDocRequest: '/tranches-payment/doc-request',
    tranchesPaymentDocDownload: '/tranches-doc/download',
    //tranchesPaymentDocDownload: '/tranches-payment/doc-download',
    user: '/rf-info/user',
    code: '/rf-info/code',
    brands: '/rf-info/brand-rb',
    models: '/rf-info/brand-rb/:brandId/model',
  },

  security: {
    passwordReset: '/security/reset-password',
    passwordChange: '/security/change-password',
  },

  variable: {
    agreement: '/variable/agreement',
    contacts: '/variable/contacts',
    contacts_rf: '/variable/contacts_rf',
    instruction: '/variable/instruction',
    tariffs: '/variable/tariffs',
    reminder: '/variable/reminder',
    reminder_rf: '/variable/reminder_rf',
    requisites: '/variable/requisites',
  },

  usedCarSettings: {
    getUsedCarSettings: '/variable/used-form',
  },

  clientLog: {
    getClientLogSettings: '/variable/client-log',
    postClientLog: '/client-log',
  },

  notifications: {
    getNotifications: '/notifications',
    getNotificationById: '/notifications/:id',
    setNotificationRead: '/notifications/:id/read',
    documentumFileDownload: '/documentum/:id',
    docListFileDownload: '/notification-data/doc-list/:id',
    getNotificationsCountNewApi: '/notifications/count',
  },
};
