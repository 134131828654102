import React from 'react';

import { DealerSystems, convertPathUsingParams } from '@rfb/common';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { Button, History } from '@rfb/ui-kit';
import routingConfig from 'configs/routing';
import { getDealerSystemConfig } from 'helpers/dealer';
import fp from 'lodash/fp';
import OperationDataTableRb from 'modules/operation-data/components/rbAvto/Table';
import OperationDataTable from 'modules/operation-data/components/repo/OperationDataTable';
import AppFullWidthTemplate from 'templates/AppFullWidthTemplate';
import styles from './index.module.css';

export const OperationDataPage = (props: TODO_ANY) => {
  const dealerSystemConfig = getDealerSystemConfig();
  let system: DealerSystems = props.match.params.system;
  if (dealerSystemConfig.rb_flag && !dealerSystemConfig.rf_flag) {
    system = DealerSystems.ROSBANK_AVTO;
  } else if (!dealerSystemConfig.rb_flag && dealerSystemConfig.rf_flag) {
    system = DealerSystems.RUSFINANCE;
  } else if (dealerSystemConfig.rb_flag && dealerSystemConfig.rf_flag) {
    system = props.match.params.system;
  }

  const renderNavButton = (item: { name: string; url: string }) => {
    return (
      <div key={item.name} className={styles.badge} role="navigation">
        <Button
          wrapperClassName={styles.button}
          view="square"
          text={item.name}
          onClick={() => {
            props.history.push(item.url);
          }}
        />
      </div>
    );
  };

  const renderContent = () => {
    let result: JSX.Element;
    switch (system) {
      default:
        result = (
          <div className={styles.main}>
            <div className={styles.top}>
              <h1 className={styles.title}>Журнал операций</h1>
            </div>
            <div style={{ marginBottom: '50px' }}>
              <History
                wrapperClassName={styles.wrapperHistory}
                type="back"
                to={'/'}
                title={'Вернуться на главную страницу'}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
              {fp.map(renderNavButton, [
                {
                  name: 'Т-Бизнес Авто',
                  url: `${convertPathUsingParams(routingConfig.operationDataSystemPicker.path, {
                    system: DealerSystems.ROSBANK_AVTO,
                  })}`,
                },
                {
                  name: 'РУСФИНАНС',
                  url: `${convertPathUsingParams(routingConfig.operationDataSystemPicker.path, {
                    system: DealerSystems.RUSFINANCE,
                  })}`,
                },
              ])}
            </div>
          </div>
        );
        break;

      case 'rb':
        result = (
          <>
            <div className={styles.top}>
              <h1 className={styles.title}>
                Журнал операций
                <span style={{ fontSize: '40px' }}> Т-Бизнес Авто</span>
              </h1>
            </div>
            <div style={{ marginBottom: '50px' }}>
              <History
                wrapperClassName={styles.wrapperHistory}
                type="back"
                to={'/operation-data'}
                title={'Журнал операций'}
              />
            </div>
            <OperationDataTableRb />;
          </>
        );
        break;
      case 'rf':
        result = (
          <>
            <div className={styles.top}>
              <h1 className={styles.title}>
                Журнал операций
                <span style={{ fontSize: '40px' }}> РУСФИНАНС</span>
              </h1>
            </div>
            <History
              wrapperClassName={styles.wrapperHistory}
              type="back"
              to={'/operation-data'}
              title={'Журнал операций'}
            />
            <OperationDataTable dealerSystem={system} />
          </>
        );
        break;
    }
    return result;
  };

  return <AppFullWidthTemplate content={renderContent()} />;
};
