import { convertPathUsingParams, DealerSystems } from '@rfb/common';
import {
  Button,
  ButtonReset,
  Condition,
  Dropdown,
  ITable,
  MessageList,
  Table as UIKitTable,
} from '@rfb/ui-kit';
import routingConfig from 'configs/routing';
import fp from 'lodash/fp';
import React, { useEffect } from 'react';
import history from 'utils/history';
import { TDTONotificationResponse } from '../../../../dto/notification';
import { INotificationState } from '../../store';
import styles from './assets/styles/index.module.css';

export interface INotificationsProps {
  notifications: TDTONotificationResponse;
  isReadOnly: boolean;
  filter: INotificationState['filter'];
  headers: ITable['headers'];
  rows: ITable['rows'];
  page: number;
  pageCount: number;
  errorList: string[];
  isLoading: boolean;
  inOutDirectionOptions: { value: string; label: string }[];
}

export interface INotificationsPropsExternal {
  notificationDealerSystem: string;
  history: {
    push: (path: string) => void;
  };
}

export interface INotificaitonSentActions {
  actions: {
    setError: Function;
    getNotifications: Function;
    resetNotificationCounters: Function;
    resetSelection: Function;
    setFilter: Function;
    resetFilterByOmitList: Function;
  };
}

const Notifications = (
  props: INotificationsProps & INotificationsPropsExternal & INotificaitonSentActions
) => {
  useEffect(() => {
    props.actions.getNotifications({
      filter: props.filter,
      system: props.notificationDealerSystem,
    });
    props.actions.resetNotificationCounters();
  }, [props.filter, props.actions, props.notificationDealerSystem]);

  const onFilterClear = () => {
    const omitList: (keyof INotificationState['filter'])[] = [];
    props.actions.resetFilterByOmitList(omitList);
  };

  const onSortingChange = (sorting: ITable['sorting']): void => {
    return props.actions.setFilter({ sorting });
  };

  return (
    <div className={styles.main}>
      <h1 className={styles.title}>
        Сообщения {props.notificationDealerSystem === 'rb' ? 'Т-Бизнес Авто' : 'РУСФИНАНС'}
      </h1>
      <div className={styles.header1}>
        <Dropdown
          wrapperClassName={styles.dropdown}
          label=""
          value={props.filter.inOutDirection}
          options={props.inOutDirectionOptions}
          onChange={(inOutDirection: string) => props.actions.setFilter({ inOutDirection })}
        />
        <Condition value={!props.isReadOnly}>
          <Button
            wrapperClassName={styles.extendedButton}
            text="Написать сообщение"
            onClick={() => {
              const targetPath: string = convertPathUsingParams(
                routingConfig.feedbackFromNotifications.path,
                {
                  dealerSystem:
                    props.notificationDealerSystem === DealerSystems.RUSFINANCE
                      ? DealerSystems.RUSFINANCE
                      : DealerSystems.ROSBANK_AVTO,
                }
              );
              return history.push(targetPath);
            }}
          />
        </Condition>
      </div>
      <div className={styles.header2}>
        <div style={{ fontSize: '14px', color: 'var(--link)' }}>
          Срок хранения сообщений - 90 дней
        </div>
        <ButtonReset
          wrapperClassName={styles.reset}
          title="СБРОСИТЬ ФИЛЬТРЫ"
          isActive={props.filter.isFilterActive}
          onClear={onFilterClear}
        />
      </div>
      <div className={styles.main}>
        <Condition value={!fp.isEmpty(props.errorList)}>
          <MessageList type="error" messages={props.errorList} />
        </Condition>
        <UIKitTable
          headers={props.headers}
          rows={props.rows}
          sorting={props.filter.sorting}
          page={props.page}
          pageCount={props.pageCount}
          isLoading={props.isLoading}
          onSortingChange={onSortingChange}
          onPageChange={(page: number) => props.actions.setFilter({ page })}
        />
      </div>
    </div>
  );
};

export default Notifications;
