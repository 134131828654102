import React from 'react';
export var Logo = function () { return (React.createElement("svg", { width: "135", height: "36", viewBox: "0 0 135 36", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M84.939 12.027C84.161 11.801 83.007 11.6 81.401 11.6C79.97 11.6 78.665 11.726 77.411 12.153C75.83 12.681 74.801 13.837 74.6 15.396H78.515C78.766 14.843 79.092 14.566 79.569 14.39C79.995 14.214 80.497 14.139 81.175 14.139C81.928 14.139 82.53 14.214 83.007 14.416C83.659 14.667 83.885 15.119 83.885 15.522C83.885 15.999 83.659 16.351 83.132 16.527C82.68 16.653 82.254 16.653 81.526 16.653H78.565V19.368H81.752C82.806 19.368 83.157 19.393 83.483 19.519C83.885 19.644 84.186 19.971 84.186 20.474C84.186 20.952 83.96 21.379 83.408 21.58C83.383 21.589 83.359 21.598 83.333 21.607C82.999 21.73 82.585 21.882 81.325 21.882C80.397 21.882 79.77 21.731 79.393 21.555C79.017 21.379 78.64 21.027 78.515 20.524H74.5C74.701 22.259 75.73 23.315 77.185 23.843C78.164 24.194 79.619 24.345 81.3 24.345C83.383 24.345 84.437 24.144 85.29 23.893C86.996 23.39 88 22.158 88 20.6C88 19.242 87.122 18.161 85.591 17.784C86.846 17.256 87.674 16.351 87.674 14.969C87.674 13.636 86.72 12.53 84.939 12.027Z", fill: "#333333" }),
    React.createElement("path", { d: "M127.088 14.548C128.941 14.548 130.243 15.455 130.619 16.79H134.5C133.974 13.817 131.345 11.701 127.188 11.701C122.43 11.65 119.5 14.422 119.5 18.05C119.5 21.628 122.43 24.4 127.188 24.4C131.395 24.375 133.924 22.283 134.475 19.285H130.644C130.268 20.646 128.941 21.578 127.088 21.578C124.759 21.578 123.331 20.016 123.331 18.05C123.331 16.085 124.759 14.548 127.088 14.548Z", fill: "#333333" }),
    React.createElement("path", { d: "M109.345 14.726H117.55V12H105.75V24H117.55V21.21H109.345V19.197H117.254V16.81H109.345V14.726Z", fill: "#333333" }),
    React.createElement("path", { d: "M93.5 19.516V24H90V12H93.5V16.5H99.5V12H103V24H99.5V19.516H93.5Z", fill: "#333333" }),
    React.createElement("path", { d: "M69.318 12H72.5V24H68.896V16.998L62.682 24H59.5V12H63.104V18.969L69.318 12Z", fill: "#333333" }),
    React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M54.94 16.707C54.297 16.455 53.455 16.379 52.54 16.379H47.567V14.764H56.321V12H44V24H52.218C53.381 24 54.247 23.899 54.89 23.647C56.251 23.117 57.167 21.83 57.167 20.139C57.167 18.423 56.301 17.237 54.94 16.707ZM53.158 20.871C52.961 20.947 52.688 20.972 52.342 20.972H47.567V19.054H52.342C52.738 19.054 53.059 19.079 53.307 19.205C53.604 19.357 53.752 19.634 53.752 19.988C53.752 20.341 53.579 20.719 53.158 20.871Z", fill: "#333333" }),
    React.createElement("path", { d: "M0 8.008C0 3.585 3.58101 0 7.99701 0H28.003C32.419 0 36 3.585 36 8.008V27.992C36 32.415 32.419 36 28.003 36H7.99701C3.58101 36 0 32.415 0 27.992V8.008Z", fill: "#DFD2BD" }),
    React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M8 0C3.582 0 0 3.582 0 8V16.945H3.879C4.64 16.945 5.02101 16.945 5.39001 16.879C5.66601 16.83 5.93599 16.753 6.19699 16.65C6.54599 16.512 6.87 16.312 7.517 15.911L26.145 4.39401C27.391 3.62301 28.014 3.23801 28.456 2.70401C28.785 2.30701 29.038 1.85201 29.204 1.36301C29.324 1.00601 29.379 0.626995 29.404 0.119995C28.953 0.0409951 28.488 0 28.014 0H8Z", fill: "url(#paint0_linear_2_22699)" }),
    React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M8 35.965H28.014C32.271 35.965 35.752 32.64 36 28.445V17.965H33.122C32.332 17.965 31.937 17.965 31.554 18.031C31.267 18.08 30.987 18.157 30.716 18.26C30.354 18.398 30.018 18.598 29.346 18.999L10.013 30.517C8.72 31.287 8.07399 31.672 7.61499 32.206C7.27299 32.604 7.00999 33.058 6.83899 33.547C6.63999 34.115 6.61299 34.739 6.60999 35.844C7.06099 35.924 7.526 35.965 8 35.965Z", fill: "url(#paint1_linear_2_22699)" }),
    React.createElement("path", { d: "M8 9H28V19.061C28 21.638 26.624 24.023 24.392 25.31L18 29L11.608 25.31C9.376 24.02 8 21.638 8 19.061V9Z", fill: "white" }),
    React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M13.55 14.014V17.056C13.979 16.585 14.757 16.27 15.649 16.27H16.6171V19.804C16.6171 20.743 16.3521 21.568 15.9631 22.021H20.1711C19.7821 21.568 19.5171 20.746 19.5171 19.807V16.27H20.485C21.374 16.27 22.1551 16.588 22.5841 17.056V14.014H13.55Z", fill: "#333333" }),
    React.createElement("defs", null,
        React.createElement("linearGradient", { id: "paint0_linear_2_22699", x1: "2.46", y1: "14.977", x2: "29.425", y2: "-1.05498", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { "stop-color": "#DED0BB" }),
            React.createElement("stop", { offset: "1", "stop-color": "#CDB998" })),
        React.createElement("linearGradient", { id: "paint1_linear_2_22699", x1: "34.595", y1: "19.934", x2: "7.16199", y2: "36.861", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { "stop-color": "#DED0BB" }),
            React.createElement("stop", { offset: "1", "stop-color": "#D5C4A9" }))))); };
export default Logo;
