import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import fp from 'lodash/fp';

import { getTransformedTableRow } from '../../helpers/transform';

import { IClientLineState, actions, name, selectors } from '../../store';

import Component, { IClientLineTableShortActions, IClientLineTableShortProps } from './component';

import storage from '../../../../utils/storage';
import { dealerSystemsKey } from '../../../auth/store';
import styles from './assets/styles/index.module.css';

const mapStateToProps = (state: { [name]: IClientLineState }): IClientLineTableShortProps => {
  const dataWithFieldList = selectors.selectDataWithFieldList(state);
  console.log(`clientLine/TableShort/mapStateToProps: dataWithFieldList = `, dataWithFieldList);
  const result = {
    headers: [
      { title: 'Hoмер договора', value: 'number', columnClassName: styles.columnNumber },
      { title: 'Статус договора', value: 'status', columnClassName: styles.columnStatus },
      { title: 'Бренд', value: 'name_brand' },
      { title: 'Продукт', value: 'name_product' },
    ],
    rows: fp.pipe(
      dataWithFieldList,
      fp.map(getTransformedTableRow)
    )([
      'number',
      'status',
      'type',
      'multi_brand',
      'agreement_number',
      'name_brand',
      'name_product',
    ]),
    pageCount: selectors.selectPageCount(state),
    filter: selectors.selectFilter(state),
    isLoading: selectors.selectIsLoading(state),
    apiErrorList: selectors.selectErrorListByField(state)('api'),
    dealerSystems: storage.get(dealerSystemsKey),
  };
  console.log(`clientLine/TableShort/mapStateToProps: result = `, result);
  return result;
};

const mapDispatchToProps = (dispatch: any): IClientLineTableShortActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
