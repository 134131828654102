import { bindActionCreators } from '@reduxjs/toolkit';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { actions } from 'modules/app/store';
import { connect } from 'react-redux';
import storage from 'utils/storage';
import Component, { IAppActions, IAppProps } from './component';

const mapStateToProps = (state: TODO_ANY): IAppProps => ({
  state,
  token: (storage as TODO_ANY).get('token'),
});

const mapDispatchToProps = (dispatch: any): IAppActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
