export interface IAttachmentDtoPart {
  attachment_file_id: string;
  attachment_file_name: string;
  attachment_file_mime_type: string;
}

export enum InOutDirection {
  OUT = 'OUT',
  IN = 'IN',
}

export enum NotificationReadStatusFilterCode {
  NO = 'false',
  YES = 'true',
  ALL = 'ALL',
}

export enum NotificationReadStatusFilterDisplay {
  NO = 'Получено',
  YES = 'Прочитано',
  ALL = 'Все',
}

export const notificationReadStatusFilterDisplay: {
  [key: string]: NotificationReadStatusFilterDisplay;
} = {
  [NotificationReadStatusFilterCode.NO]: NotificationReadStatusFilterDisplay.NO,
  [NotificationReadStatusFilterCode.YES]: NotificationReadStatusFilterDisplay.YES,
  [NotificationReadStatusFilterCode.ALL]: NotificationReadStatusFilterDisplay.ALL,
};

export const notificationReadStatusFilterCode: { [key: string]: NotificationReadStatusFilterCode } =
  {
    [NotificationReadStatusFilterDisplay.NO]: NotificationReadStatusFilterCode.NO,
    [NotificationReadStatusFilterDisplay.YES]: NotificationReadStatusFilterCode.YES,
    [NotificationReadStatusFilterDisplay.ALL]: NotificationReadStatusFilterCode.ALL,
  };
