import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import fp from 'lodash/fp';

import { getTransformedAmount } from '@rfb/common';

import {
  getTransformedNumberForView,
  getTransformedNumberForViewDC,
} from '../../helpers/transform';

import { ClientLineStatus } from 'modules/client-line/configs/status';
import { TrancheStatus } from '../../configs/status';

import {
  actions as clientLineActions,
  selectors as clientLineSelectors,
} from 'modules/client-line/store';

import { actions, ITrancheState, name, selectors } from '../../store';

import { TrancheTypes } from 'modules/tranche/configs/type';
import storage from '../../../../utils/storage';
import { readOnlyRoleKey } from '../../../app/store/sagas';
import Component, {
  ITrancheFilterActions,
  ITrancheFilterProps,
  ITrancheFilterPropsExternal,
  selectedByPeriodValue,
} from './component';

const mapStateToProps = (
  state: { [name]: ITrancheState },
  props: ITrancheFilterPropsExternal
): ITrancheFilterProps & ITrancheFilterPropsExternal => {
  const clientLine = clientLineSelectors.selectCurrentClientLine(state);

  return {
    filter: selectors.selectFilter(state),
    filterTemp: selectors.selectFilterTemp(state),
    clientLineStatusOptions: [
      { value: ClientLineStatus.OPENED, label: 'По открытым договорам' },
      { value: selectedByPeriodValue, label: 'Выданные за период' },
    ],
    clientLineName: fp.isEmpty(clientLine)
      ? ''
      : clientLine.type !== TrancheTypes.DC
      ? getTransformedNumberForView(clientLine.number, clientLine.type)
      : getTransformedNumberForViewDC(clientLine.agreement_number),

    clientLine,
    statusOptions: [
      { value: TrancheStatus.OPENED, label: 'Открытые транши' },
      { value: TrancheStatus.ALL, label: 'Все транши' },
    ],
    paymentListCount: selectors.selectPaymentListCount(state),
    amount: fp.pipe(selectors.selectPaymentListAmount, getTransformedAmount)(state),
    showSelected: selectors.selectShowSelected(state),
    clientLineId: props.clientLineId,
    clientLineType: props.clientLineType,
    isReadOnly: storage.get(readOnlyRoleKey),
    multiBrand: props.multiBrand,
  };
};

const mapDispatchToProps = (dispatch: any): ITrancheFilterActions => ({
  actions: bindActionCreators(actions, dispatch),
  clientLineActions: bindActionCreators(clientLineActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
