import React, { useMemo } from 'react';

import { Condition, History, MessageList } from '@rfb/ui-kit';
import routingConfig from 'configs/routing';
import { useLocation } from 'react-router-dom';

import AppFullWidthTemplate from 'templates/AppFullWidthTemplate';

import { DealerSystems, convertPathUsingParams } from '@rfb/common';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { getDealerSystemConfig } from 'helpers/dealer';
import FTTableRepo from 'modules/financial-block/components/FTTableRepo';
import { useSelector } from 'react-redux';
import FTTable from '../../../modules/financial-block/components/FTTable';
import FTTableUsed from '../../../modules/financial-block/components/FTTableUsed';
import { isUsedCars } from '../../../modules/financial-block/helpers';
import { selectors } from '../../../modules/financial-block/store';
import styles from './assets/styles/index.module.css';

const Main = (props: TODO_ANY) => {
  const isNoCreditLines = useSelector((state) => selectors.selectIsNoCreditLines(state));
  const { search } = useLocation();
  const params = useMemo(() => {
    const urlParams = new URLSearchParams(search);
    return {
      brand_type: urlParams.get('brand_type'),
      code_type: urlParams.get('code_type'),
      state: urlParams.get('state'),
    };
  }, [search]);

  // TODO Устранить дублирование (вынести в общую функцию)
  const dealerSystemConfig = getDealerSystemConfig();
  let isOnlyOneSystem = false;
  if (dealerSystemConfig.rb_flag && !dealerSystemConfig.rf_flag) {
    isOnlyOneSystem = true;
  } else if (!dealerSystemConfig.rb_flag && dealerSystemConfig.rf_flag) {
    isOnlyOneSystem = true;
  }
  const isRosbank = props.match.params.system === DealerSystems.ROSBANK_AVTO;
  const isRf = props.match.params.system === DealerSystems.RUSFINANCE;
  const dealerSystemDisplay = isRosbank ? 'Т-Бизнес Авто' : 'РУСФИНАНС';
  const codeTypeParameter = params.code_type ?? props.match.params.type;
  const carTypeDisplay =
    codeTypeParameter === 'used'
      ? 'Автомобили с пробегом'
      : codeTypeParameter === 'new'
      ? 'Новые автомобили'
      : 'Демо';

  function getBackUrl() {
    let backUrl: string;
    if (isOnlyOneSystem) {
      if (isRosbank) {
        if (codeTypeParameter === 'new' || codeTypeParameter === 'used') {
          backUrl = routingConfig.main.path;
        } else {
          backUrl = routingConfig.financialBlockFinancialBlockPicker.path;
        }
      } else {
        if (codeTypeParameter === 'new' || codeTypeParameter === 'demo') {
          backUrl = routingConfig.main.path;
        } else {
          backUrl = routingConfig.financialBlockFinancialBlockPicker.path;
        }
      }
    } else {
      if (isRosbank) {
        backUrl = convertPathUsingParams(
          params.code_type
            ? routingConfig.financialBlockFinancialBlockPicker.path
            : routingConfig.financialBlockFinancialBlockCarTypePicker.path,
          {
            system: props.match.params.system,
          }
        );
      } else {
        const isMixed = true; // Для РФ пока так
        if (isMixed) {
          backUrl = convertPathUsingParams(
            routingConfig.financialBlockFinancialBlockCarTypePicker.path,
            { system: props.match.params.system }
          );
        } else {
          backUrl = routingConfig.financialBlockFinancialBlockPicker.path;
        }
      }
    }
    return backUrl;
  }

  const isDemo =
    props.history.location.pathname.includes('/demo') ||
    props.history.location.search.includes('code_type=demo');
  const isRosbankNew = isRosbank && !isUsedCars(props.history);
  const renderContent = () => (
    <div className={styles.main}>
      <div className={styles.top}>
        <h1 className={styles.title}>
          Запрос на финансирование
          <Condition value={!isNoCreditLines}>
            <span style={{ fontSize: '40px' }}> В {dealerSystemDisplay}</span>
          </Condition>
        </h1>
        <Condition value={!isNoCreditLines}>
          <h2 className={styles.subtitle}>{carTypeDisplay}</h2>
        </Condition>

        <div style={{ marginTop: '40px' }}>
          <History
            wrapperClassName={styles.wrapperHistory}
            type="back"
            to={getBackUrl()}
            title={`${
              isOnlyOneSystem
                ? isRosbank && !(params.code_type === 'new' || params.code_type === 'used')
                  ? `Запрос на финансирование ${params.code_type ? '' : dealerSystemDisplay}`
                  : 'Вернуться на главную страницу'
                : isRosbank
                ? `Запрос на финансирование ${params.code_type ? '' : dealerSystemDisplay}`
                : `Запрос на финансирование`
            }`}
          />
        </div>
      </div>
      <Condition value={isRosbank && isNoCreditLines}>
        <div style={{ marginTop: '50px' }}>
          <MessageList
            type="error"
            messages={['Нет доступных кредитных линий для отправки запроса на кредитную заявку']}
          />
        </div>
      </Condition>
      {/* Для РФ codes пока не запрашивается */}
      <Condition value={!isNoCreditLines || isRf}>
        <div className={styles.content}>
          <Condition value={isRosbank}>
            <Condition value={isRosbankNew}>
              <FTTable
                brand_type={params.brand_type ? params.brand_type : undefined}
                state={params.state ? params.state : undefined}
                history={props.history}
              />
            </Condition>
            <Condition value={!isRosbankNew}>
              <FTTableUsed
                state={params.state ? params.state : undefined}
                history={props.history}
              />
            </Condition>
          </Condition>
          <Condition value={isRf}>
            <FTTableRepo
              state={params.state ? params.state : undefined}
              history={props.history}
              codeType={codeTypeParameter}
            />
          </Condition>
        </div>
      </Condition>
    </div>
  );

  return <AppFullWidthTemplate content={renderContent()} />;
};

export default Main;
