"use strict";
exports.__esModule = true;
exports.pluralize = void 0;
/**
 * Пример:
 * pluralize(2020, ['год', 'года', 'лет']); // 2002 год
 * pluralize(20, ['год', 'года', 'лет']); // 20 лет
 * @param n
 * @param forms
 */
exports.pluralize = function (n, forms) {
    return forms[n % 10 === 1 && n % 100 !== 11
        ? 0
        : n % 10 >= 1.0000000001 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
            ? 1
            : 2];
};
