import React from 'react';

import { downloadLog, Logger } from '@rfb/common';
import { AuthTemplate, Button } from '@rfb/ui-kit';
import { actions as appActions } from 'modules/app/store';
import { useDispatch } from 'react-redux';
import styles from './assets/styles/index.module.css';

interface AppErrorProps {
  message?: string;
}
const AppError: React.FC<AppErrorProps> = ({ message }) => {
  console.log(`AppError BEGIN: message = ${JSON.stringify(message)}`);
  const dispatch = useDispatch();
  const renderContent = () => (
    <div className={styles.main}>
      <div className={styles.title}>
        {message && <h2>{message}</h2>}
        <h2>Возможно, произошла ошибка.</h2>
        <h2>Обратитесь в поддержку.</h2>
      </div>
      <>
        <div>
          <h3 className={styles.subTitle}>Действия с журналом работы приложения</h3>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', paddingTop: '20px' }}>
          <Button
            text="Отправить на сервер"
            onClick={() => dispatch(appActions.postClientLog({}))}
          />
          <Button text="Сохранить в файле" onClick={() => downloadLog(Logger.getInstance())} />
        </div>
      </>
    </div>
  );

  console.log(`AppError BEFORE RETURN`);
  return <AuthTemplate content={renderContent()} />;
};

export default AppError;
