import React, { useEffect } from 'react';

import { Button, DatePickerRange, Dropdown } from '@rfb/ui-kit';

import { dates } from '../../configs/defaultAccount';

import { IAccountsState } from '../../store';
import Balance from './components/Balance';
import Dates from './components/Dates';
import Restrictions from './components/Restrictions';
import Summary from './components/Summary';

import {
  getToday,
  getTomorrow,
  getYesterday,
} from 'modules/accounts/helpers/transform/yesterdayAndTodaySelector';
import styles from './assets/styles/index.module.css';

export interface IAccountsSelectProps {
  optionList: { label: string; value: string }[];
  account: string;
  accounts: IAccountsState['accounts'];
  filter: IAccountsState['filter'];
  isLoading: boolean;
}

export interface IAccountsSelectActions {
  actions: {
    getAccounts: Function;
    getAccountArrests: Function;
    setFilter: Function;
    setCurrentAccount: Function;
    exportTransactionsData: Function;
    exportAccountStatement1СFormat: Function;
    exportAccountExtendedStatement: Function;
  };
}

const AccountsFilter = (props: IAccountsSelectProps & IAccountsSelectActions) => {
  useEffect(() => {
    props.actions.getAccounts();
    // ESLINT Необходимо выполнение только при монтировании компонента
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.filter.account) {
      props.actions.setCurrentAccount(props.filter.account);
    }
  }, [props.filter, props.actions]);

  useEffect(() => {
    if (props.filter.account) {
      props.actions.getAccountArrests(props.filter);
      props.actions.setFilter(dates);
    }
    // ESLINT Нужен только при изменяющемся р/с
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filter.account]);

  useEffect(() => {
    // Первый расчётный счет - должен быть открытым, если уже не установлен
    if (!props.account) {
      const openAccounts = props.accounts.find((acc) => !acc.close_date);
      const firstAccount = openAccounts ? openAccounts.account : null;
      props.actions.setFilter({ account: firstAccount });
    }
    // ESLINT Нужен только при изменяющемся списке р/с
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.accounts]);

  const onAccountChange = (account: string): void => {
    if (account !== props.filter.account) {
      props.actions.setFilter({ account });
    }
  };
  const onDatesChange = (newDates: string[]): void =>
    props.actions.setFilter({ start: newDates[0], end: newDates[1] });

  function formatDate(date: Date): string {
    return date.toISOString().slice(0, 10);
  }
  const onYesterday = (): void => {
    props.actions.setFilter({ start: formatDate(getYesterday()), end: formatDate(getYesterday()) });
  };

  const onToday = (): void => {
    props.actions.setFilter({ start: formatDate(getToday()), end: formatDate(getToday()) });
  };

  const onSaveTo1C = (): void => {
    console.log('onSaveTo1c BEGIN: props.filter = ', props.filter);
    props.actions.exportAccountStatement1СFormat({
      account: props.account,
      start: props.filter.start,
      end: props.filter.end,
    });
    console.log('onSaveTo1c END');
  };

  const onSaveToExcel = (): void => {
    props.actions.exportAccountExtendedStatement({
      account: props.account,
      dateStart: props.filter.start,
      dateEnd: props.filter.end,
    });
  };

  const startDate: string = (props.filter.start || dates.start).toString();
  const endDate: string = (props.filter.end || dates.end).toString();

  console.log('AccountsFilter: props.account = ', props.account);
  console.log('AccountsFilter: props.filter = ', props.filter);

  return (
    <div>
      <div className={styles.filterAccount}>
        <Dropdown
          wrapperClassName={styles.dropDown}
          label="Счёт"
          options={props.optionList}
          value={props.account}
          onChange={onAccountChange}
        />
        <div
          style={{
            marginLeft: '40px',
            display: 'flex',
            justifyContent: 'space-between',
            gap: '10px',
          }}
        >
          <Button wrapperClassName={styles.button} text="Выгрузка для 1С" onClick={onSaveTo1C} />
          <Button
            wrapperClassName={styles.button}
            text="Сохранить в Excel"
            onClick={onSaveToExcel}
          />
        </div>
        <Balance />
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Dates />
          <Restrictions />
          <div style={{ display: 'flex', gap: '40px', alignItems: 'baseline' }}>
            <div className={styles.chooseBlock}>
              <DatePickerRange
                wrapperClassName={styles.datePickerRange}
                start={startDate}
                end={endDate}
                withShift
                onChange={(value: string[]) => {
                  if (!!value[0] && !!value[1]) {
                    // К сожалению, пока костыль, поскольку по непонятным причинам onChange срабатывает, когда его не просят - просто при рендеринге
                    onDatesChange(value);
                  }
                }}
              />
            </div>
            <div style={{ display: 'flex', gap: '20px' }}>
              <button style={{ fontWeight: '500', padding: '5px 15px' }} onClick={onYesterday}>
                Вчера
              </button>
              <button style={{ fontWeight: '500', padding: '5px 15px' }} onClick={onToday}>
                Сегодня
              </button>
            </div>
          </div>
          <Summary />
        </div>
      </div>
    </div>
  );
};

export default AccountsFilter;
